
import React, { useState } from 'react';
import { Modal, Nav, Navbar, NavLink, NavDropdown, Form, FormControl, Col, Button } from "react-bootstrap";
import { Redirect, Link } from 'react-router-dom';
import IosTrash from 'react-ionicons/lib/IosTrash';
import IosMenu from 'react-ionicons/lib/IosMenu';
import IosBookOutline from 'react-ionicons/lib/IosBookOutline';
import MdFolderOpen from 'react-ionicons/lib/MdFolderOpen';
import IosInformationCircleOutline from 'react-ionicons/lib/IosInformationCircleOutline';
import IosBookmark from 'react-ionicons/lib/IosBookmark';
import IosClose from 'react-ionicons/lib/IosClose';

const Toast = (props) => {
    let classes = 'toast-box toast-bottom';
    let context = ' bg-primary';
    if (props.show) {
        classes += ' show';
    }
    if (props.context) {
        context = ' bg-' + props.context;
    }
    classes += context;
    let modal = <div id="toast-13" className={classes}>
        <div className="in">
            <div className="text">
                {props.message}
            </div>
        </div>
        <button onClick={() => { props.close(false); }} type="button" className="btn btn-sm btn-text-light close-button">OK</button>
    </div>;

    return modal;
}

export default (Toast);
