import React from 'react';
import { BrowserRouter as Router, Switch, useLocation, withRouter, matchPath, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import routes from "../../routes";

const Breadcrumbs = (props) => {
    let breadcrumbs = null;
    let location = useLocation();
    let match = null;

    for(let i = 0; i < routes.length; i++) {
        let route = routes[i];
        match = matchPath(location.pathname, {
            path: route.path,
            exact: true,
            strict: false
        });
        if (match !== null) {
            break;
        }
    }

    let backLinks = [
        {
            path: '/list/create',
            text: 'Back to lists',
            link: '/'
        },
        {
            path: '/list/:listid',
            text: 'Back to lists',
            link: '/'
        },
        {
            path: '/list/:listid/:bookid',
            text: 'Back to list',
            link: '/list/:listid'
        },
        {
            path: '/list/:listid/add',
            text: 'Back to list',
            link: '/list/:listid'
        },
        {
            path: '/list/:listid/add/:bookid',
            text: 'Back to search results',
            link: '/list/:listid/add'
        }
    ];
    if (match !== null) {
        backLinks = backLinks.filter((link) => {
            return link.path === match.path;
        });
    
        if (backLinks.length > 0) {
            let backLink = backLinks[0];
            let href = backLink.link;
            let text = backLink.text;
            for (var key in match.params) {
                if (match.params.hasOwnProperty(key)) {
                    href = href.replace(":" + key, match.params[key]);
                }
            }
            breadcrumbs = <Breadcrumb>
                <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: href }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;{text}
                </Breadcrumb.Item>
            </Breadcrumb>;
        }    
    }

    return breadcrumbs;
}

export default withRouter(Breadcrumbs);