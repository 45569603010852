export default [
    {
        path: '/',
        title: 'Reading Lists',
        add: '/list/create',
        add_text: 'Create',
        icon: 'IosBookmark'
    },
    {
        path: '/list/create',
        title: 'Create List',
        back: '/'
    },
    {
        path: '/list/:listid',
        title: 'List Detail',
        back: '/'
    },
    {
        path: '/mydata',
        title: 'My Data',
        back: '/'
    },
    {
        path: '/about',
        title: 'About',
        back: '/'
    },
    {
        path: '/list/:listid/:bookid',
        title: 'Book Detail',
        back: '/list/:listid'
    },
    {
        path: '/list/:listid/add',
        title: 'Add Book',
        back: '/list/:listid'
    },
    {
        path: '/list/:listid/add/:bookid',
        title: 'Book',
        back: '/list/:listid/add'
    }
];