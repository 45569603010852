import React, { Component } from 'react';
import { Card, Col, Button, Nav, NavItem } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import IosTrash from 'react-ionicons/lib/IosTrash';
import IosBook from 'react-ionicons/lib/IosBook';
import ListItem from '../ListItem/ListItem';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const BookList = (props) => {
    let handleListFiltered = (key) => {
        if (props.filterChanged) {
            props.filterChanged(key);
        }  
    }

    let handleRemoveClick = (event) => {
        props.removeListItem(props.listId);
        props.history.push("/");
    };
    let btns = [];

    if (props.addClicked) {
        btns.push(<Button key="addBtn" onClick={props.addClicked} className="mx-2" variant="success"><FontAwesomeIcon icon={faPlusCircle} />&nbsp;Add books</Button>);
    }

    if (props.removeClicked) {
        btns.push(
            <ConfirmDialog />
        );
    }

    let newBooks = [...props.list.books];
    let key = props.filter;
    newBooks = newBooks.filter((book) => {
        if (key === 'complete') {
            return book.completed === true;
        }

        if (key === 'incomplete') {
            return  typeof book.completed === 'undefined' || book.completed === false;
        }
        return true;
    });

    let view = null;
    if (props.list.books.length == 0) {
        view = <React.Fragment>
            <div className="section-heading padding mt-3">
                <h2 className="title">{ props.list.title }</h2>
                <span>by {props.list.creator}</span>
            </div>
            <div className="section mt-2 text-center">
                <IosBook color="#958d9e" fontSize="52px" />
                <h2>List is empty</h2>
                <p className="mb-3">Add some books</p>
            </div>;
        </React.Fragment>
    } else {
        let items = newBooks.map((item, index) => {    
        let newItem = {};
            newItem.id = item.id;
            newItem.title = item.title;
            newItem.subtitle = item.creator;
            newItem.image = item.images.smallThumbnail;
        
            let handleViewClick = () => {
                let listId =  props.listId;
                props.history.push("/list/" + listId + '/' + item.id);
            };
        
            let handleRemoveClick = (event) => {
                props.removeItem(props.listId, item.id);
                props.history.push("/");
            };

            return <ListItem 
                key={item.id}
                item={newItem}
                removeClicked={handleRemoveClick}
                viewClicked={handleViewClick} 
            />
        });

        let results;
        if (items.length == 0) {
            results = <div className="section mt-2 text-center">
                <div className="row justify-content-center">
                <div className="col-9 col-md-3">
                <IosBook color="#958d9e" fontSize="52px" />
                <h2>No Books</h2>
                <p className="mb-3">You don't have any books of this type in the current list.</p>
                </div>
                </div>
            </div>;
        } else {
            results = <ul className="listview image-listview media insetx x">
                {items}
                </ul>;
        }

        let nav = <Nav onSelect={handleListFiltered} className="lined" variant="tabs" defaultActiveKey={props.filter}>
            <Nav.Item>
                <Nav.Link eventKey="all">All</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="incomplete">Incomplete</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="complete">Complete</Nav.Link>
            </Nav.Item>
        </Nav>;

        let filter = <div className="section">
            <Card className="tabbed-listview-header">
                <Card.Body>
                {nav}
                {results}
                </Card.Body>
            </Card>
        </div>;

        view = <React.Fragment>
            <div className="tabbed-listview mb-3">
            <div className="section-heading padding mt-3">
                <h2 className="title">{ props.list.title }</h2>
                <span>by {props.list.creator}</span>
            </div>
            <div className="d-none listview-title mt-1 text-dark">{ props.list.title } by {props.list.creator}</div>
            {filter}

            </div>
        </React.Fragment>;
    }

    return (
        <div id="appCapsule">
            {view}
            <div className="section mb-2">
                <ConfirmDialog 
                    title="Delete this list"
                    body="Are you sure?"
                    actionText="DELETE"
                    confirm={handleRemoveClick}
                />
             </div>
        </div>
    );   
};

export default withRouter(BookList);