import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './custom.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, useHistory } from 'react-router-dom';
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
import "firebase/analytics";

let production = true;
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    production = false;
}

var firebaseConfig = {
    apiKey: "AIzaSyB8awvqIdV4bDPBcp038_BssT1jxALWzRA",
    authDomain: "reading-list-dfa11.firebaseapp.com",
    databaseURL: "https://reading-list-dfa11.firebaseio.com",
    projectId: "reading-list-dfa11",
    storageBucket: "reading-list-dfa11.appspot.com",
    messagingSenderId: "170048211608",
    appId: "1:170048211608:web:64b78145a85b29ded4deed",
    measurementId: "G-3G0ZHH1LWM"
  };

let analytics;
if (production) {
    // Initialize Firebase
    analytics = firebase.analytics;
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}

const logCurrentPage = () => {
    if (production) {
        analytics().setCurrentScreen(window.location.pathname);
        analytics().logEvent('screen_view');
    }
};

let AnalyticsComponent = () => {
    const history = useHistory();
    useEffect(() => {
        logCurrentPage(); // log the first page visit
        history.listen(() => {
        logCurrentPage();
        });
    }, [history]);
    return (<div></div>);
};

if (!production) {
    //AnalyticsComponent = null;
}

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AnalyticsComponent />
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
