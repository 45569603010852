import React from 'react';
import { Button, InputGroup, FormControl, InputGroupAppend } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faBookOpen } from "@fortawesome/free-solid-svg-icons";

const SearchInput = (props) => {
    const onKeyPress = (e) => {
        if(e.which === 13) {
            e.preventDefault();
            props.searchBooks();
        }
      }
    return (
        <div className="form-group searchbox">
            <div className="input-group w-100">
                <InputGroup className="mb-0">
                    <FormControl
                        onKeyPress={onKeyPress}
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        onChange={props.searchChanged}
                    />
                    <InputGroup.Append>
                    <Button 
                        disabled={props.isLoading}
                        onClick={props.searchBooks}
                        variant="info"
                        className="border">{props.isLoading ? 'Loading…' : <FontAwesomeIcon icon={faSearch} />}
                    </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        </div>
    );
};

export default SearchInput;