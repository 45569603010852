import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IosMenu from 'react-ionicons/lib/IosMenu';
import IosBookmarksOutline from 'react-ionicons/lib/IosBookmarksOutline';
import IosFolderOutline from 'react-ionicons/lib/IosFolderOutline';
import IosInformationCircleOutline from 'react-ionicons/lib/IosInformationCircleOutline';

const Sidebar = (props) => {

    return (
        <div className="appBottomMenu">        
            <Link to="/" className="item">
                <div className="col">
                    <IosBookmarksOutline color="black" fontSize="24px" />
                    <strong>My Lists</strong>
                </div>
            </Link>
            <Link to="/mydata" className="item">
                <div className="col">
                    <IosFolderOutline color="black" fontSize="24px" />
                    <strong>My Data</strong>
                </div>
            </Link>
            <Link to="/about" className="item">
                <div className="col">
                    <IosInformationCircleOutline color="black" fontSize="24px" />
                    <strong>About</strong>
                </div>
            </Link>
        </div>
    );
}

export default Sidebar;