// Import Libraries
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Route, Switch, withRouter, Redirect, Link }  from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// Import functions
import { filteredBooks, saveState, loadState, getState, formattedTime, mergeLists, isIos, isInStandaloneMode } from './common/utility';
import routes from "./routes";
import headers from "./headers";

// Import Custom Components
import BottomMenu from './Components/BottomMenu/BottomMenu';
import Sidebar from './Components/Sidebar/Sidebar';
import Header from './Components/Header/Header';
import Toast from './Components/Toast/Toast';
import NotFound from './Components/NotFound/NotFound';
import Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs';
import ReadingList from './Components/ReadingList/ReadingList';
import CreateReadingList from './Components/CreateReadingList/CreateReadingList';
import BookList from './Components/BookList/BookList';
import BookSearch from './Components/BookSearch/BookSearch';
import BookDetail from './Components/BookDetail/BookDetail';
import MyData from './Components/MyData/MyData';
import About from './Components/About/About';
import InstallAlert from './Components/InstallAlert/InstallAlert';
import './App.scss';

class App extends Component {
    state = {
        isHomescreen: Cookies.get('homescreen'),
        isLoading: false,
        isValidated: false,
        search: {
            term: "",
            lastTerm: "",
            books: [],//bookList3()
            maxResults: 12,
            startIndex: 0,
            totalItems: 0,
            isTouched: false
        },
        bookList: {
            filter: "all"
        },
        list: []
    };

    //  Lifecycle functions
    componentWillMount() {
        this.loadLocalState();
    }

    //  Helper functions
    saveLocalState = () => {
        const persistState = {list: this.state.list};
        saveState(persistState);
    }

    loadLocalState = () => {
        const persistState = loadState();
        if (typeof persistState !== 'undefined') {
            let state = {...this.state};
            state.list = persistState.list;
            this.setState(state);
        }
    }

    //  Handler functions
    handleExportData = () => {
        let state = getState();
        let time = formattedTime();
        let filename = "my_lists_" + time + ".json";
        let blob = new Blob([state], {type: "application/json"});
        let saveAs = window.saveAs;
        saveAs(blob, filename);   
    }

    handleImportData = (data) => {
        let merged = mergeLists(data);
        if (merged.length > 0) {
            this.setState({list: merged}, () => {
                this.saveLocalState();
            }); 
            return true;
        }
        return false;
    }
    
    handleSetFormValidated = (isValidated) => {
        this.setState({isValidated: isValidated});
    }

    handleAddToBookList = (props, listId) => {
        props.history.push("/list/" + listId + '/add');
    }

    handleSearchChanged = (event) => {
        let search = {...this.state.search};
        search.term = event.target.value;
        this.setState({
            search: search
        });
    }

    handleSearchBooks = (event) => {
        this.fetchBooks(false);
    }

    handleFilterChanged = (newFilter) => {
        this.setState({bookList: {filter: newFilter}});
    }

    handleLoadMore = (event) => {
        this.loadMore();
    }

    handleAddListItem = (list) => {
        let lists = [...this.state.list];
        let id = uuidv4(); 
        list.id = id;
        list.books = [];
        lists.push(list);
        this.setState({list: lists}, () => {
            this.saveLocalState();
        }); 
    }

    handleAddSearchItem = (listId, book) => {
        let list = [...this.state.list];
        let listData;
        var listIndex = list.findIndex(function (element) { 
            return element.id == listId;
        }); 
        if (listIndex > -1 && listIndex in list) {
            listData = list[listIndex];
            listData.books.push(book);
            list[listIndex] = listData;
            this.setState({list: list});
            this.saveLocalState();
        }
    }

    handleRemoveListItem = (itemId) => {
        const list = this.state.list.filter(item => item.id != itemId);
        this.setState({ list: list }, () => {
            this.saveLocalState();
        }); 
    }

    handleRemoveBookItem = (listId, itemId) => {
        let list = [...this.state.list];
        var listIndex = this.state.list.findIndex(function (element) { 
            return element.id == listId;
        }); 
        const bookList = list[listIndex].books.filter(item => item.id !== itemId);
        list[listIndex].books = bookList;
        this.setState({ list: list }, () => {
            this.saveLocalState();
        }); 
    }

    handleUpdateBookItem = (book) => {
        let newList = [...this.state.list];
        let stateUpdate = false;
        for(let i = 0 ; i < newList.length; i++) {
            let list = newList[i];
            let books = list.books;
            let index = books.findIndex((listBook) => book.id === listBook.id);
            if (index > -1) {
                newList[i].books[index] = book;
                stateUpdate = true;
            }
        }

        if (stateUpdate) {
            this.setState({ list: newList }, () => {
                this.saveLocalState();
            }); 
        }
    }

    // Event Functions
    loadMore = () => {
        let search = {...this.state.search};
        search.startIndex = this.state.search.books.length;
        this.setState({
            search: search
        });
        this.fetchBooks(true);
    }

    fetchBooks = (loadMore) => {
        this.setState({isLoading: true});
        let live = true;
        let url;
        if (live) {
            url = 'https://www.googleapis.com/books/v1/volumes';
        } else {
            url = 'https://run.mocky.io/v3/a0ea2795-f170-4de8-bc09-908b0ca60921';
            //url = 'https://run.mocky.io/v3/98aeffd0-7375-421a-a198-395eb2dfad7b';
        }
        
        if (live && this.state.search.term.length === 0) {
            return false;
        }
        let apiKey = 'AIzaSyDTSbV24eWkoiykiXHQTnVnXb9j0yYUWPE';
        let params = { 
            params: {
                q: this.state.search.term,
                maxResults: this.state.search.maxResults,
                startIndex: this.state.search.startIndex,
                apiKey: apiKey
            }
        };

        axios.get(url, params)
        .then(response => {
            let books = filteredBooks(response.data);
            let totalItems = response.data.totalItems;
            let search = {...this.state.search};

            if (books.length > 0) {
                // If its a new search then just replace the book list
                if (!loadMore || search.books.length === 0) {
                    search.books = books;
                } else {
                    search.books = search.books.concat(books);
                }
            }
            search.lastTerm =  '' + this.state.search.term;
            search.startIndex = search.books.length;
            search.totalItems = totalItems;
            search.isTouched = true;
            this.setState({
                search: search,
                isLoading: false
            });
        })
        .catch(error => {
            this.setState({
                isLoading: false
            });
        })
    }

    //  Route functions
    routeBookList = (props) => { 
        let listId = props.match.params.listid;
        let listData = {};
        var listIndex = this.state.list.findIndex(function (element) { 
            return element.id == listId;
        });
        if (this.state.list === 0 || listIndex === -1) {
            props.history.push('/');
            return;
        }
        if (listIndex > -1 && listIndex in this.state.list) {
            listData = this.state.list[listIndex]
        }
        let bookList =  <BookList
            key={listId}
            listId={listId} 
            list={listData}
            filter={this.state.bookList.filter}
            filterChanged={this.handleFilterChanged}
            removeListItem={this.handleRemoveListItem}
            removeItem={this.handleRemoveBookItem} 
            addClicked={() => { this.handleAddToBookList(props, listId) }}
        />;

        let addHandler = () => {
            return () => {
                this.handleAddToBookList(props, listId)
            }
        }
        headers[2].add = addHandler;
        headers[2].add_text = 'Add Book';

        return bookList;
    }

    routeBookListCreate = (props) => {
        let createReadingList = <CreateReadingList
            key="createReadingList"
            setFormValidated={this.handleSetFormValidated}
            validated={this.state.isValidated}
            addListItem={this.handleAddListItem}
        />;
        let view = createReadingList;
        return view;
    }

    routeMyData = (props) => {
        let myData = <MyData
            key="myData"
            exportData={this.handleExportData}
            importData={this.handleImportData}
        />;
        let view = myData;
        return view;
    }

    routeAbout = (props) => {
        let about = <About
            key="about"
        />;
        let view = about;
        return view;
    }

    routeBookListBookDetail = (props) => {
        let listId = props.match.params.listid;
        let bookId = props.match.params.bookid;
        let book;
        let items = [];
        let listIndex = this.state.list.findIndex(function (element) { 
            return element.id == listId;
        }); 
        if (listIndex > -1 && listIndex in this.state.list) {
            items = this.state.list[listIndex].books;
            let bookIndex = items.findIndex(function (element) { 
                return element.id == bookId;
            }); 
            if (bookIndex > -1 && bookIndex in items) {
                book = items[bookIndex];
            }
        }

        let view = <BookDetail 
            key={book.id} item={book} 
            removeItem={this.handleRemoveBookItem}
            updateItem={this.handleUpdateBookItem}
        />;

        return view;
    }

    routeBookListSearch = (props) => {
        let listId = props.match.params.listid;
        let listData = {};
        let list;
        let listIndex = this.state.list.findIndex(function (element) { 
            return element.id == listId;
        }); 
        if (listIndex > -1 && listIndex in this.state.list) {
            list = this.state.list[listIndex];
        }
        let view = <BookSearch 
            key="2"
            isLoading={false}
            searchChanged={(event) => this.handleSearchChanged(event)}
            searchBooks={(event) => this.handleSearchBooks(event)}
            loadMore={(event) => this.handleLoadMore(event)}
            listId={listId} 
            list={list}
            search={this.state.search} 
            removeItem={this.handleRemoveBookItem} 
        />;

        return view;
    }

    routeBookListSearchBookDetail = (props) => { 
        let listId = props.match.params.listid;
        let bookId = props.match.params.bookid;
        let book;
        let items = this.state.search.books;

        if (items.length === 0) {
            props.history.push('/list/' + listId);
            return;
        }
        let bookIndex = items.findIndex(function (element) { 
            return element.id == bookId;
        }); 
        if (bookIndex > -1 && bookIndex in items) {
            book = items[bookIndex];
        }
        let addItem = this.handleAddSearchItem;
        let removeItem = this.handleRemoveBookItem;
        let listIndex = this.state.list.findIndex(function (element) { 
            return element.id == listId;
        }); 

        let list = this.state.list[listIndex];    
        bookIndex = list.books.findIndex(function (element) { 
            return element.id == bookId;
        }); 
        if (bookIndex > -1) {
            addItem = null;
        } else {
            removeItem = null;
        }

        let view = <BookDetail
            key={book.id}
            item={book}
            removeItem={removeItem}
            addItem={addItem}
        />
        
        return view;
    }

    routeReadingList = (props) => {
        let listId = 0;
        let readingList = <ReadingList
            key="reading_list"
            items={this.state.list}
            removeItem={this.handleRemoveListItem}
        />;

        return  readingList;
    }

    render (props) {
        let allRoutes = routes.map((route, key) => {
            let renderRoute = <Route
                key={key}
                path={route.path}
                exact
                render={ 
                    (props) => {
                        return  [
                            <Header key="header_1" headers={headers} listCount={this.state.list.length} />,
                            this[route.render](props)
                        ]
                    }
                } 
            />;

            return renderRoute;
        });

        allRoutes.push(<Route key="notFound" component={NotFound} />);

        let view = (
            <Switch>
                {allRoutes}
            </Switch>
        )

        let showInstallAlert =  false;
        
        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
          showInstallAlert = true;
        }

        let show = !this.state.isHomescreen && showInstallAlert;

        return (
            <div className="App">
            {view}
            <BottomMenu />
            <InstallAlert show={show} />
            </div>
        );
    }
}

export default withRouter(App);