import books1 from '../data/books-poirot.json';
import books2 from '../data/books-potter.json';
import books3 from '../data/books-jamie.json';
import _ from 'lodash';

export const bookList1 = () => {
    return filteredBooks(books1);
};
  
export const bookList2 = () => {
    return filteredBooks(books2);
}

export const bookList3 = () => {
    return filteredBooks(books3);
}
  
export const filteredBooks = (books) => {
    let filtered = books.items;
    return filtered.map((book) => {
        let images = {};
        if (!book.volumeInfo.imageLinks) {
            images = {
                smallThumbnail: 'https://plchldr.co/i/128x195?&bg=0066ff&fc=ffffff&text=No%20Image'
            }
        } else {
            images = book.volumeInfo.imageLinks;
        }
        let newBook = {
            id: book.id,
            title: book.volumeInfo.title,
            subtitle: book.volumeInfo.subtitle,
            authors: book.volumeInfo.authors,
            description: book.volumeInfo.description,
            images: images,
            publishedDate: book.volumeInfo.publishedDate
        };
        return newBook;
    });
}

export const getState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return serializedState;
    } catch (err) {
        return undefined;
    }
};

export const mergeLists = (lists) => {
    let state = loadState();
    let allLists = [];

    if (typeof state !== 'undefined') {
        if (_.has(state, 'list')) {
            allLists = state.list;
        }
    }
    
    if (_.has(lists, 'list')) {
        let list = lists.list;
        if (Array.isArray(list) && list.length > 0) {

            if (allLists.length === 0) {
                allLists = list;
            } else {
                list.forEach((item, index) => {
                    if (_.has(item, 'id')) {
                        let listId = item.id;
                        let listIndex = allLists.findIndex((listItem) => {
                            return listItem.id == listId;
                        });
    
                        if (listIndex > -1) {
                            allLists[listIndex] = item;
                        } else {
                            allLists.push(list);
                        }
                    }
                });
            }
        }
    }
    return allLists;
}

export const loadState = () => {
    let state = getState();
    if (typeof state !== 'undefined') {
        state = JSON.parse(state);
    }
    return state;
};
  
export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const formattedTime = () => {
    let today = new Date();
    let y = today.getFullYear();
    // JavaScript months are 0-based.
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + "" + m + "" + d + "" + h + "" + mi + "" + s;
}

// Detects if device is on iOS 
export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
export const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
