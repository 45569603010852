import React from 'react';
import { withRouter }  from 'react-router-dom';
import { Card, Row, Col, Button } from "react-bootstrap";
import { Redirect } from 'react-router-dom';
import MdAdd from 'react-ionicons/lib/MdAdd';
import IosTrash from 'react-ionicons/lib/IosTrash';
import IosAdd from 'react-ionicons/lib/IosAdd';

const BookDetail = (props) => {
    if (typeof props.item === 'undefined' ) {
        return <Redirect to='/'/>;
    }

    let listId = props.match.params.listid;

    let handleRemove = () => {
        props.removeItem(listId, props.item.id);
        props.history.push('/list/' + listId);
    }

    let handleAdd = () => {
        props.addItem(listId, props.item);
        props.history.push('/list/' + listId);
    }
    let btns = [];

    if (props.addItem) {
        btns.push(
            <button key="addBtn" onClick={ handleAdd } className="btn btn-block btn-success">
            <IosAdd color="#FFFFFF" fontSize="26px" />Add to list</button>
        );
    }

    if (props.removeItem) {
        btns.push(
            <button key="removeBtn" onClick={ handleRemove } className="btn btn-block btn-danger">
            <IosTrash color="#FFFFFF" fontSize="26px" />Delete from list</button>
        );
    }
    let authors = null;
    authors = props.item.authors.map((item, index) => {
        return <span key={item + index} className="mr-2">{item}</span>;
    });

    let actions = null;
    if (props.updateItem) {
        let handleChange = (event) => {
            let newItem = {...props.item};
            newItem.completed = event.target.checked;
            props.updateItem(newItem);
        }

        actions = <div className="d-flex justify-content-between">
            <div className="text-dark font-weight-bold">Completed</div> 
            <div className="custom-control custom-switch">
                <input
                    defaultChecked={props.item.completed}
                    onChange={handleChange}
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch1" />
                <label className="custom-control-label" htmlFor="customSwitch1"></label>
            </div>
        </div>
    }

    let card = <Card className="">
        <Card.Body>
            <Row>
                <Col md="auto">
                    <Card.Img style={{width: 'max-content'}} variant="" src={props.item.images.thumbnail} />
                </Col>
                <Col md={9}>
                    <Card.Title className="mt-3 mt-md-0">{props.item.title}</Card.Title>
                    <Card.Subtitle className="mb-1">{props.item.subtitle}</Card.Subtitle>
                    <Card.Text className="mb-1">
                    by {authors}
                    </Card.Text>
                    <Card.Text>
                    {props.item.publishedDate}
                    </Card.Text>
                    <Card.Text>
                    {props.item.description}
                    </Card.Text>
                    {actions}
                </Col>
            </Row>
        </Card.Body>
    </Card>

    return (
        <div id="appCapsule">
            <div className="section mt-2 mb-2">
                {card}
            </div>

            <div className="section mb-2">
            {btns}
            </div>
        </div>
    )
};

export default  withRouter(BookDetail);