import React from 'react';
import { Card, Button } from "react-bootstrap";
import IosMailOutline from 'react-ionicons/lib/IosMailOutline';

const About = (props) => {
    return (
        <div id="appCapsule" className="">
            <div className="section mt-3 mb-3">
                <div className="section-heading d-none">
                    <h2 className="title">My Data</h2>
                </div>

                <Card>
                    <Card.Body>
                        <Card.Title>About</Card.Title>
                        <Card.Text>
                        Reading Lists is a free web app, for tracking your personal reading. It allows you to create lists of books for any purpose.  This could be books you want to read, have finished reading or want to share with others.  You can then record when you have finished reading the book.
                        </Card.Text>
                        <Card.Text>
                        Reading Lists is designed to be simple and minimal.  It only has one purpose as a reading journal app.  All your data is stored on the device, in the browser local storage. So you don’t need to login or worry about storing data on a server.  To give you full control, you can also export/import all your data in JSON format.  This allows you to backup your lists or move them to other devices.
                        </Card.Text>
                        <Card.Text>
                        This app is based on an iOS app called ReadingList that I released on the App Store in 2016.  The feedback from that was to allow data to be exported and have multiple lists.  So those features are included in this version.
                        </Card.Text>
                        </Card.Body>
                </Card>
            </div>

            <div className="section mt-3 mb-3">
                <Card>
                    <Card.Body>
                    <Card.Title>Apple iOS</Card.Title>
                    <Card.Text>
                    Starting in iOS and iPadOS 13.4 and Safari 13.1 on macOS, there is a seven-day cap on all web app storage.  If you are using Reading Lists on these devices please <a target="_blank" href="https://www.howtogeek.com/667910/how-to-add-a-website-to-your-iphone-or-ipad-home-screen/">add to homescreen</a> which will avoid this cap.  Otherwise Apple iOS may delete your lists.  Alternatively you can export your data to a JSON file.  
                    </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            
            <div className="section mt-3 mb-3">
                <Card>
                    <Card.Body>
                    <Card.Title>Have a Question?</Card.Title>
                    <Card.Text>
                    Feel free to contact us with any feedback or problems about Reading Lists.  I'm glad to hear any feature requests as well! We will get back to you as soon as possible.
                    </Card.Text>
                    <a target="_blank" href="mailto:david@davidlobo.co.uk?subject=ReadingLists+App+Enquiry" className="btn btn-primary"><IosMailOutline className="mr-1" fontSize="26px" /><span>Contact</span></a>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
};
    
export default About;