import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
<div id="appCapsule">

<div className="section">
    <div className="splash-page mt-5 mb-5">
        <h1>404</h1>
        <h2 className="mb-2">Page not found!</h2>
        <p>
        The requested URL was not found on this server. That’s all we know.
        </p>
    </div>
</div>

<div className="fixed-bar">
    <div className="row">
        <div className="col-12">
            <a href="/" className="btn btn-lg btn-primary btn-block goBack">Go Back</a>
        </div>
    </div>
</div>
</div>
);

export default NotFound;