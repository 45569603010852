
export default [
    { path: "/list/:listid/add/:bookid", name: "Book Detail", render: "routeBookListSearchBookDetail" },
    { path: "/list/:listid/add", name: "Book Search", render: "routeBookListSearch" },
    { path: "/list/:listid/:bookid", name: "Book Search", render: "routeBookListBookDetail" },
    { path: "/list/create", name: "Create List", render: "routeBookListCreate" },
    { path: "/mydata", name: "My Data", render: "routeMyData" },
    { path: "/about", name: "About", render: "routeAbout" },
    { path: "/list/:listid", name: "Book List", render: "routeBookList" },
    { path: "/", name: "Lists", render: "routeReadingList" },
];