import React from 'react';
import { Link, withRouter }  from 'react-router-dom';
import { Button } from "react-bootstrap";

const ListItem = (props) => {
    let itemData = props.item;
    let btns = [];

    if (props.viewClicked) {
        btns.push(<Button key="viewBtn" onClick={props.viewClicked} className="mx-2" variant="outline-info">View</Button>);
    }

    if (props.addClicked) {
        btns.push(<Button key="addBtn" onClick={props.addClicked} className="mx-2" variant="outline-success">Add</Button>);
    }

    if (props.removeClicked) {
        btns.push(<Button key="removeBtn" onClick={props.removeClicked} className="mx-2" variant="outline-danger">Remove</Button>);
    }

    let image = itemData.image;
    let badge = null;
    if (props.badge != null) {
        badge = <span className="badge badge-primary">{props.badge}</span>;
    }

    let item = <li>
        <a onClick={props.viewClicked} className="item">
            <div className="imageWrapper">
                <img src={image} alt="image" className="imaged h64" />
            </div>
            <div className="in">
                <div>
                    {itemData.title}
                    <div className="text-muted">{itemData.subtitle}</div>
                </div>
                {badge}
            </div>
        </a>
    </li>;

    return item;
}

export default ListItem;