import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Card, Row, Col, Button, InputGroup, Form, FormText, FormControl, Feedback, FormLabel } from "react-bootstrap";
import { saveAs } from 'file-saver';
import _ from 'lodash';
import context from 'react-bootstrap/esm/AccordionContext';
import MdArrowDropupCircle from 'react-ionicons/lib/IosCloudUploadOutline';
import Toast from '../Toast/Toast';

const MyData = (props) => {
    let defaultToast = {show: false, message: null, context: "primary"};
    const [toast, setToast] = useState(defaultToast);
    const [isFileSelected, setIsFileSelected] = useState(false);
    
    let toastBox = null;
    let closeToast = () => {setToast(defaultToast)};
    toastBox = <Toast
        show={toast.show}
        context={toast.context}
        message={toast.message}
        close={closeToast} 
    />;

    const handleFileChange = (event) => {
        setIsFileSelected(true);
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        let file = form.file.files[0];
        var reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function(file) {
            return function(e) {
                let jsonObj = JSON.parse(e.target.result);
                let imported = props.importData(jsonObj);
                let newToast = {...defaultToast};
                if (imported) {
                    newToast.message = 'Lists have been successfully imported';
                    newToast.context = 'success';
                    newToast.show = true;
                } else {
                    newToast.message = 'Lists could not be imported';
                    newToast.context = 'danger';
                    newToast.show = true; 
                }
                setIsFileSelected(false);
                setToast(newToast)
                setTimeout(closeToast, 5000);
                return;

            };
        })(file);

        if (file) {
            reader.readAsText(file);
        }
    };

    return (
        <div id="appCapsule" className="">
            <div className="section mt-3">
                <div className="section-heading d-none">
                    <h2 className="title">My Data</h2>
                </div>

                <Card>
                    <Card.Body>
                        <Card.Title>Export Lists</Card.Title>
                        <Card.Text>
                        You may export all your current reading lists in JSON format.  This allows you to keep it safe and load it on other devices as you wish.
                        </Card.Text>
                        <Button onClick={props.exportData} variant="primary">Export Lists</Button>
                    </Card.Body>
                </Card>
            </div>

            <div className="section mt-2">
                <Card>
                    <Card.Body>
                    <Card.Title>Import Lists</Card.Title>
                    <Card.Text>
                    You can also import reading lists in compatible JSON format.  Any existing versions of the same list will be overwritten.
                    </Card.Text>

                        <Form onSubmit={handleSubmit}>
                            <div className="custom-file-upload">
                                <input onChange={handleFileChange} name="file" type="file" id="fileuploadInput" accept=".json" />
                                <label htmlFor="fileuploadInput">
                                    <span>
                                        <strong>
                                            <MdArrowDropupCircle fontSize="52px" />
                                            <i>Upload JSON File</i>
                                        </strong>
                                    </span>
                                </label>
                            </div>
                            <div className="mt-2">
                            <Button disabled={ isFileSelected ? '' : 'disabled' } variant="btn btn-primary" type="submit">
                                Import List
                            </Button>
                            </div>
                        </Form>

                    </Card.Body>
                </Card>
            </div>
            {toastBox}
        </div>
        )
    };
    
export default withRouter(MyData);