import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import IosTrash from 'react-ionicons/lib/IosTrash';

const ConfirmDialog = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleConfirm = () => {
        props.confirm();
        handleClose();
    };
  
    return (
        <React.Fragment>
            <Button onClick={handleShow} className="btn btn-block btn-danger">
            <IosTrash color="#FFFFFF" fontSize="26px" />Delete this list</Button> 
            <Modal className="dialogbox" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{ props.title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ props.body }</Modal.Body>
                    <Modal.Footer>
                        <div className="btn-inline">
                            <Button variant="btn" className="btn btn-text-secondary" onClick={handleClose}>
                                CANCEL
                            </Button>
                            <Button variant="btn" className="btn btn-text-primary" onClick={handleConfirm}>
                                { props.actionText }
                            </Button>
                        </div>
                    </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
  }

  export default ConfirmDialog;