import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Card, Row, Col, Button, InputGroup, Form, FormText, FormControl, Feedback, FormLabel } from "react-bootstrap";

const CreateReadingList = (props) => {
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() !== false) {
            props.addListItem({
                title: form.name.value,
                creator: form.creator.value,
                books: []
            });
            props.history.push('/');
        }
        props.setFormValidated(true);
    };
    let view = <Form noValidate validated={props.validated} onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="basic">
            <div className="input-wrapper">
            <Form.Label className="label">List Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter a name for the list" />
            <Form.Control.Feedback type="invalid">
                Please provide a valid list name
            </Form.Control.Feedback>
            </div>
        </Form.Group>
        <Form.Group controlId="creator" className="basic">
            <div className="input-wrapper">
            <Form.Label className="label">List Creator</Form.Label>
            <Form.Control required type="text" placeholder="Enter your name" />
            <Form.Control.Feedback type="invalid">
                Please provide your name
            </Form.Control.Feedback>
            </div>
        </Form.Group>
        <div className="mt-2">
        <Button variant="btn btn-primary btn-lg btn-block" type="submit">
            Create List
        </Button>
        </div>
        </Form>
      
      return (
        <div id="appCapsule">
            <div className="section mt-3">
                <div className="section-heading">
                    <h2 className="title">Create New List</h2>
                </div>
                <div className="section-title d-none">Create New List</div>
                    <Card>
                        <Card.Body>
                        {view}
                        </Card.Body>
                    </Card>
            </div>
        </div>
      )
    };
    
export default withRouter(CreateReadingList);