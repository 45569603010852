import React from 'react';
import ListItem from '../ListItem/ListItem';
import SearchInput from './SearchInput/SearchInput';
import { Card, Row, Col, Button, InputGroup, FormControl, InputGroupAppend } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import IosBook from 'react-ionicons/lib/IosBook';
import IosSearch from 'react-ionicons/lib/IosSearch';

const bookSearch = (props) => {
    let searchInput = <SearchInput 
        isLoading={props.isLoading} 
        searchChanged={props.searchChanged}
        searchBooks={props.searchBooks}
    />

    let items = props.search.books.map((item, index) => {
        let newItem = {};
        newItem.id = item.id;
        newItem.title = item.title;
        newItem.subtitle = item.creator;
        newItem.image = item.images.smallThumbnail;
    
        let handleViewClick = () => {
            let listId =  props.listId;
            props.history.push("/list/" + listId + '/add/' + item.id);
        };

        // @todo - Key is not unique as API returns books with same id 
        // e.g search David Mitchell
        return <ListItem 
            key={item.id} 
            item={newItem} 
            viewClicked={handleViewClick} 
        />
    });

    let searchResults = null;
    let loadMore = <div className="section mb-2">
        <a onClick={props.loadMore} className="btn btn-block btn-info">Load More</a>
    </div>;

    if (props.search.books.length >= props.search.totalItems) {
        loadMore = null;
    }

    if (props.search.books.length == 0) {
        let title = <h2>Search for Books</h2>;
        let body = <p className="mb-3">Add to this list by searching</p>;
        if (props.search.isTouched) {
            title = <h2>No Books Found</h2>;
            body = <p className="mb-3">Try another search</p>;
        }
        let noResults = <React.Fragment>
            <IosSearch color="#958d9e" fontSize="52px" />
            {title}
            {body}
        </React.Fragment>;

        searchResults = <div className="section mt-2 text-center">
        {noResults}</div>;
    } else {
        searchResults = <React.Fragment>
            <div className="section mt-1 mb-2">
                <div className="section-title">Found { props.search.totalItems } results for <span className="text-primary">{props.search.lastTerm}</span></div>
                <div className="card">
                    <ul className="listview image-listview media transparent flush">
                    {items}
                    </ul>
                </div>
            </div>
            {loadMore}
        </React.Fragment>
    }
    let searchView = (
        <React.Fragment>
            <p>Search for books to add to your current list</p>
            {searchInput}
            {searchResults}
        </React.Fragment>
    );

    return (
        <div id="appCapsule" className="extra-header-active">
            <div className="extraHeader">
                <form className="search-form">
                {searchInput}
                </form>
            </div>
            <div className="section-heading padding mt-3 d-none">
                <h2 className="title">{ props.list.title }</h2>
                <span>by {props.list.creator}</span>
            </div>
            <div className="d-none listview-title text-dark">{ props.list.title } by { props.list.creator }</div>
            {searchResults}
        </div>
    )
};

export default withRouter(bookSearch);