import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import MdListBox from 'react-ionicons/lib/MdListBox';
import ListItem from '../ListItem/ListItem';

const ReadingList = (props) => {
    let view;
    let items = props.items.map((item, index) => {
        let image = 'https://plchldr.co/i/128x195?&bg=0066ff&fc=ffffff&text=No%20Image';
        let newItem = {};
        newItem.id = item.id;
        newItem.title = item.title;
        newItem.subtitle = item.creator;
        newItem.image = image; 

        let handleViewClick = () => {
            props.history.push("/list/" + item.id);
        };

        let handleRemoveClick = (event) => {
            props.removeItem(item.id);
        };

        let badge = 0;

        if (item.books && item.books.length > 0) {
            badge = item.books.length;
            newItem.image = item.books[0].images.smallThumbnail;
        }

        return <ListItem 
            key={item.id} 
            item={newItem}
            badge={badge}
            removeClicked={handleRemoveClick}
            viewClicked={handleViewClick} 
        />
    });

    let btns = [];
    btns.push(
        <Link key="linkListCreate" to="/list/create">
        <Button key="addBtn" className="mx-2" variant="success"><FontAwesomeIcon icon={faPlusCircle} />&nbsp;Add list</Button>
        </Link>
    );

    if (items.length == 0) {
        view  = <div className="section mt-2 text-center">
            <MdListBox color="#958d9e" fontSize="52px" />
            <h2>No Lists Found</h2>
            <p className="mb-3">Add some lists</p>
        </div>;
    } else {
        view = <ul className="listview image-listview media inset mb-2">
            {items}
            </ul>;
    }

    return (
        <div id="appCapsule">
            <div className="section-heading padding mt-3">
                <h2 className="title">My Lists</h2>
                <a href="app-blog.html" className="d-none link">View All</a>
            </div>
            <div className="d-none listview-title mt-1 text-dark">My Reading Lists</div>
            {view}
        </div>
    );
};

export default  withRouter(ReadingList);